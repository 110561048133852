var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ma-3"},[_c('h1',[_vm._v("Пользователи")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"w-100"},[_c('TableUnit',{attrs:{"infoReport":_vm.infoReport,"loadAddRaw":_vm.loadAddRaw,"loading":_vm.loading,"itemClasses":_vm.itemClasses,"dataTable":_vm.dataTable[0],"dialogAddItem":_vm.dialogAddItem,"actionsButtons":true,"dialogRemove":_vm.dialogRemove,"loadSubCatalog":_vm.loadSubCatalog,"helpbox":[
            { color: 'yellow lighten-4', title: 'с проектом' },
            { color: 'green lighten-4', title: 'администраторы' } ],"expandable":{
            field: 'projects',
            filterFields: function (f) { return !['role', 'role_project'].includes(f); },
          },"chips":{
            field: 'projects',
            slot: "item.projects",
            ifnull: 'Расширенные права',
            filter: function (f) { return f; },
            color: function (v) { return _vm.colorRoles[v.role_project]; },
            title: 'role_project_title',
          },"boolsCheckIcon":{
            field: 'edit',
            slot: "item.edit",
            icon: 'mdi-check-bold',
            color: 'green',
          },"initRemoveRaw":_vm.removeRaw,"editRaw":_vm.editRaw},on:{"openEditDialog":_vm.openEditDialog,"openAddDialog":_vm.openAddDialog,"getSubCatalog":_vm.getSubCatalog,"edit":_vm.edit,"add":_vm.add,"find":_vm.find,"remove":_vm.remove,"closwDialogAddItem":_vm.closwDialogAddItem,"closeDialogRemove":_vm.closeDialogRemove,"openDialogRemove":_vm.openDialogRemove,"changeSubSelect":_vm.changeSubSelect}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }