import { mdiHelpCircleOutline } from "@mdi/js";
//import catalogsParam from './../settings/catalogs/params';
export default {
    dataTable: [{
        name: "userauth",
        // title: "Связки",
        // tooltip: '<b>Связки</b> - это соотношение источника, группы, площадки и воркера на заданный период, для построения финальных отчетов',
        // tooltipIcon: mdiHelpCircleOutline,
        table: [],
        icon: 'mdi-vector-triangle',
        headers: [],
        multi: true,
        add: {
            title: "Добавление участников",
            titleEdit: "Редактирование участников",
            name: "add_members",
            data: [

                {
                    type: "text",
                    title: "Логин, эл. почта",
                    name: "login",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: true,
                    required: true,
                    dialog: true,
                },
                {
                    type: "text",
                    title: "Имя",
                    name: "name",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: true,
                    required: true,
                    dialog: true,
                },
                {
                    type: "text",
                    title: "Фамилия",
                    name: "sername",
                    placeholder: "",
                    model: null,
                    items: [],
                    filter: true,
                    required: true,
                    dialog: true,
                },

                {
                    type: "select",
                    title: "Роль в системе",
                    name: "id_role",
                    placeholder: "",
                    model: null,
                    required: true,
                    filter: true,
                    dialog: true,
                    // tooltip: `Вы можете создать статусы прямо отсюда, нажам на кнопку <b>+</b>, или перейти в <a href="/settings/catalogs" target="_blank">справчоники</a> и создать там`,
                    // tooltipIcon: mdiHelpCircleOutline,
                    // addTempItemIcon: 'mdi-plus-thick',
                    // addTempData: catalogsParam.dataTable[0].add.data,
                    itemText: 'title',
                },
                {
                    type: "select",
                    title: "Роль в проектах",
                    name: "id_role_project",
                    placeholder: "",
                    model: null,
                    required: true,
                    filter: true,
                    hiddenDiaog: true,
                    // tooltip: `Вы можете создать статусы прямо отсюда, нажам на кнопку <b>+</b>, или перейти в <a href="/settings/catalogs" target="_blank">справчоники</a> и создать там`,
                    // tooltipIcon: mdiHelpCircleOutline,
                    // addTempItemIcon: 'mdi-plus-thick',
                    // addTempData: catalogsParam.dataTable[0].add.data,
                    itemText: 'title',
                },
                // {
                //     type: "text",
                //     title: "Отчество",
                //     name: "secondname",
                //     placeholder: "",
                //     model: null,
                //     items: [],
                //     filter: false,
                //     required: false,
                // },
                {
                    type: "select",
                    title: "Проект",
                    name: "id_project",
                    placeholder: "",
                    model: null,
                    required: true,
                    filter: true,
                    hiddenDiaog: true,
                    tooltip: `Вы можете выбрать проект для этого участника или добавить участника прямо из проекта`,
                    tooltipIcon: mdiHelpCircleOutline,
                    // addTempItemIcon: 'mdi-plus-thick',
                    // addTempData: catalogsParam.dataTable[0].add.data,
                    itemText: 'title',
                },

                {
                    type: "text",
                    title: "ID",
                    name: "id",
                    placeholder: "",
                    model: null,
                    filter: true,
                    required: true,
                    hiddenDiaog: true,
                },
                {
                    type: "select",
                    title: "Разрешено редактирование",
                    name: "projects",
                    placeholder: "",
                    model: [],
                    multi: true,
                    filter: false,
                    required: true,
                    hiddenDiaog: true,
                    block: '12',
                    itemText: 'title',
                },

                {
                    type: "select",
                    title: "Разрешено редактирование",
                    name: "permissions",
                    placeholder: "",
                    model: [],
                    items: [],
                    multi: false,
                    filter: false,
                    required: true,
                    hiddenDiaog: true,
                },



                // {
                //     type: "text",
                //     title: "Контакты",
                //     name: "contacts",
                //     placeholder: "",
                //     model: null,
                //     items: [],
                //     filter: false,
                //     required: true,
                // },

            ],
        },
    }],
}