var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-100",staticStyle:{"background":"#f2f2f2"}},[_c('v-row',{staticClass:"h-100 w-100",attrs:{"no-gutters":"","justify":"center","align":"center"}},[_c('v-card',{staticClass:"pa-10 rounded-lg",attrs:{"elevation":"0","max-width":"400px"}},[_c('v-btn',{staticClass:"close-btn",attrs:{"icon":"","light":""},on:{"click":function () {
            this$1.$emit('close', false);
            this$1.resetValidateRegistration();
            this$1.alertErrorCreateAccount = false;
            this$1.err = '';
          }}},[_c('v-icon',[_vm._v("mdi-close")])],1),_c('v-row',{attrs:{"no-gutters":"","justify":"center"}},[_c('v-card-title',{staticClass:"h1 pa-0 font-weight-bold"},[_vm._v("Создать учетную запись")]),_c('v-form',{ref:"formRegistration",staticClass:"w-100"},[_c('v-alert',{staticClass:"mt-4",staticStyle:{"font-size":"14px !important"},attrs:{"dense":"","text":"","color":"red","value":_vm.alertErrorCreateAccount}},[_vm._v(" "+_vm._s(_vm.err)+" ")]),_vm._l((_vm.registration),function(reg,name){return _c('v-row',{key:name,staticClass:"my-4",attrs:{"no-gutters":""}},[_c('h5',{staticClass:"w-100"},[_vm._v(_vm._s(reg.label))]),_c('v-text-field',{staticClass:"mt-1",attrs:{"rules":['name', 'sername', 'password'].includes(name)
                  ? [_vm.rules.required]
                  : name === 'login'
                  ? [_vm.rules.required, _vm.rules.emailMatch]
                  : [],"background-color":"#ffffff","dense":"","outlined":"","type":reg.type,"disabled":reg.disabled,"placeholder":reg.placeholder,"hide-details":"","prepend-inner-icon":reg.icon},model:{value:(reg.model),callback:function ($$v) {_vm.$set(reg, "model", $$v)},expression:"reg.model"}})],1)}),_c('v-btn',{staticClass:"new-btn",attrs:{"elevation":"0","block":"","color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.createAccount}},[_vm._v("Зарегистрироваться")]),_c('p',{staticClass:"subtitle-2 mb-0 mt-5 grey--text darken-3"},[_vm._v(" Уже зарегистрировались? "),_c('v-btn',{staticClass:"link-btn",attrs:{"small":"","text":"","color":"primary"},on:{"click":function () {
                  this$1.$emit('close', false);
                  this$1.$emit('iversion');
                  this$1.$router.push({ path: 'login' });
                  this$1.resetValidateRegistration();
                  this$1.alertErrorCreateAccount = false;
                  this$1.err = '';
                }}},[_vm._v("Войдите")])],1)],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }