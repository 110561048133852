var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"ma-3"},[_c('h1',[_vm._v("Проекты")]),_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"w-100"},[_c('TableUnit',{attrs:{"infoReport":_vm.infoReport,"loadAddRaw":_vm.loadAddRaw,"loading":_vm.loading,"itemClasses":_vm.itemClasses,"dataTable":_vm.dataTable[0],"dialogAddItem":_vm.dialogAddItem,"dialogRemove":_vm.dialogRemove,"loadSubCatalog":_vm.loadSubCatalog,"initRemoveRaw":_vm.removeRaw,"editRaw":_vm.editRaw,"helpbox":[{ color: 'red lighten-4', title: 'в архиве' }],"chips":{
            field: 'members',
            slot: "item.members",
            filter: function (f) { return f.id === this$1.$auth.user().id; },
            color: function (v) { return _vm.colorRoles[v.role_project_name]; },
            title: 'role_project',
          },"linkAdd":_vm.linkAdd},on:{"openEditDialog":_vm.openEditDialog,"openAddDialog":_vm.openAddDialog,"getSubCatalog":_vm.getSubCatalog,"edit":_vm.edit,"add":_vm.add,"find":_vm.find,"remove":_vm.remove,"closwDialogAddItem":_vm.closwDialogAddItem,"closeDialogRemove":_vm.closeDialogRemove,"openDialogRemove":_vm.openDialogRemove,"handleClickRow":_vm.handleClickRow}})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }