var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-app',[_c('v-navigation-drawer',{attrs:{"mobile-breakpoint":0,"mini-variant":_vm.mini,"dark":"","app":""},on:{"update:miniVariant":function($event){_vm.mini=$event},"update:mini-variant":function($event){_vm.mini=$event}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(!_vm.mini)?_c('p',{staticClass:"text-center white--text caption"},[_vm._v(" Produced by MagicSoft 2021 ")]):_vm._e()]},proxy:true}])},[_c('v-list',{attrs:{"dense":"","app":""}},[_c('v-list-item-group',{attrs:{"dark":""},on:{"change":function () {
              _vm.subMenuModel = null;
            }}},[_vm._l((_vm.menu.filter(
              function (f) { return !f.sub_items &&
                (_vm.$auth.roles() !== 'admin'
                  ? ![
                      'Настройки',
                      'Пользователи',
                      'Цели',
                      'Продукты' ].includes(f.name)
                  : 1 === 1); }
            )),function(item){return _c('router-link',{key:item.name,attrs:{"tag":"v-list-item","to":item.path,"active-class":"active","exact":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(item.name))])],1)],1)}),_vm._l((_vm.menu.filter(
              function (f) { return f.sub_items &&
                (_vm.$auth.roles() !== 'admin'
                  ? !['Настройки', 'Пользователи'].includes(f.name)
                  : 1 === 1); }
            )),function(item,index){return _c('v-list-group',{key:index,attrs:{"no-action":"","color":""},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(item.name))])],1)]},proxy:true}],null,true),model:{value:(item.model),callback:function ($$v) {_vm.$set(item, "model", $$v)},expression:"item.model"}},[_c('v-list-item-group',{attrs:{"dark":""},model:{value:(_vm.subMenuModel),callback:function ($$v) {_vm.subMenuModel=$$v},expression:"subMenuModel"}},_vm._l((item.sub_items),function(child){return _c('router-link',{key:child.name,attrs:{"tag":"v-list-item","to":child.path,"active-class":"active","exact":""}},[_c('v-list-item-icon',[_c('v-icon')],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(child.name))])],1)],1)}),1)],1)})],2)],1)],1),_c('v-app-bar',{attrs:{"dark":_vm.mini,"flat":"","app":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){$event.stopPropagation();_vm.mini = !_vm.mini}}}),_c('v-spacer'),(!this.$auth.check())?_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"cols":"auto"}},[_c('v-btn',{staticClass:"new-btn mr-3",attrs:{"elevation":"0","light":""},on:{"click":function($event){_vm.$router.push({ path: 'login' }).catch(function () {})}}},[_vm._v("Войти")]),_c('v-btn',{staticClass:"new-btn",attrs:{"elevation":"0","color":"primary"},on:{"click":function($event){_vm.$router.push({ path: 'register' }).catch(function () {})}}},[_vm._v("Зарегистрироваться")])],1):_vm._e(),(this.$auth.check())?_c('v-col',{staticClass:"d-none d-md-flex",attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-3",staticStyle:{"font-size":"20px"},attrs:{"elevation":"0","color":"primary","small":"","fab":""}},'v-btn',attrs,false),on),[_vm._v(_vm._s(_vm.generateAvatar()))])]}}],null,false,2707571406)},[_c('div',{staticClass:"white",staticStyle:{"min-width":"320px","z-index":"2"}},[_c('Account')],1)])],1):_vm._e()],1),_c('v-main',[_c('router-view')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }