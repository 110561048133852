// import { mdiFormatListGroup } from '@mdi/js';

export default {
    // dialogAddItem: false,
    // dialogRemove: false,
    // editRaw: null,
    removeRaw: null,
    itemsSub: [],
    multiSelected: [],
    // iconGrop: mdiFormatListGroup
}